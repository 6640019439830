<template>
    <div
        class="d-md-flex align-items-baseline justify-content-between"
        :class="[showAlignmentSetting ? 'flex-md-row' : 'flex-md-row-reverse']"
    >
        <div v-if="showAlignmentSetting">
            <label class="font-weight-bold mb-1 f-11 text-muted">Alignment</label>
            <Alignment v-model="settings.alignment" @input="updateData" />
        </div>
        <div v-if="showSizeSetting" class="col-md-4 d-flex flex-column">
            <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                <div class="font-weight-bold text-nowrap">Size</div>
                <div class="text-secondary">{{ sizeInInches }} in</div>
            </label>
            <div class="d-flex f-10 align-items-center mb-0 mt-1">
                <b-form-input
                    v-model="settings.size"
                    type="range"
                    min="48"
                    max="200"
                    step="1"
                    @input="sizeUpdated"
                ></b-form-input>
            </div>
        </div>
        <div v-if="showTextPlacementSetting" :class="textPlacementClass">
            <label class="font-weight-bold mb-1 f-11 text-muted">Text Placement</label>
            <div class="d-flex w-100" :class="[showAlignmentSetting ? 'flex-column-reverse' : 'flex-row']">
                <div class="d-flex align-items-center" :class="{ 'mr-3': !showAlignmentSetting }">
                    <b-form-radio v-model="settings.text_placement" value="below" @change="updateData">
                        <div class="text-muted f-12" style="height: 1.5rem; line-height: 1.5rem">Below</div>
                    </b-form-radio>
                </div>
                <div class="d-flex align-items-center">
                    <b-form-radio v-model="settings.text_placement" value="above" @change="updateData">
                        <div class="text-muted f-12" style="height: 1.5rem; line-height: 1.5rem">Above</div>
                    </b-form-radio>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Alignment from './alignment.vue'
import { defaultInlineImagesSettings } from '../store/helpers/documentHelpers'
import { debounce } from 'lodash'

export default {
    name: 'InlineImagesManager',
    components: {
        Alignment,
    },
    props: {
        showSizeSetting: {
            type: Boolean,
            default: false,
        },
        showAlignmentSetting: {
            type: Boolean,
            default: false,
        },
        showTextPlacementSetting: {
            type: Boolean,
            default: false,
        },
        imagesSettings: {
            type: Object,
            default: () => ({ ...defaultInlineImagesSettings }),
        },
    },
    data() {
        return {
            settings: {
                ...this.imagesSettings,
            },
        }
    },
    computed: {
        ...mapGetters({
            isFlashcard: 'document/isFlashcard',
        }),
        textPlacementClass() {
            return {
                'w-100': !this.showAlignmentSetting && !this.showSizeSetting,
                'w-50': !this.showAlignmentSetting,
                invisible: this.hideTextPlacement,
            }
        },
        hideTextPlacement() {
            return this.isFlashcard && this.settings.alignment !== 'center'
        },
        sizeInInches() {
            if (this.settings.size === '50') return '0.5'
            const size = this.settings.size / 96
            if (size > 2) return '2.0'
            if (size < 0.5) return '0.5'
            return size.toFixed(1)
        },
    },
    methods: {
        updateData() {
            this.$emit('change', { ...this.settings })
        },
        sizeUpdated: debounce(function () {
            this.updateData()
        }, 100),
    },
}
</script>
